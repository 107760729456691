import {
  Box,
  Divider,
  Layout,
  Page,
  SkeletonBodyText,
} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {EnhancedCard, SkeletonPageActions} from '~/components';
import {useIdFromUrl, usePreviousRouteInfo} from '~/hooks';
import {DetailsPageMode} from '~/types/shared';
import {createI18nConfig} from '~/utils/createI18nConfig';
import {productRecommendationsURL} from '~/utils/urls';

import translations from './translations';

export function ProductRecommendationsDetailsSkeleton() {
  const [i18n] = useI18n(createI18nConfig(translations));
  const id = useIdFromUrl();
  const mode = id ? DetailsPageMode.Update : DetailsPageMode.Create;

  const backAction = usePreviousRouteInfo({
    content: i18n.translate(
      'ProductRecommendationsDetailsSkeleton.navigation.productRecommendations',
    ),
    url: productRecommendationsURL(),
  });

  return (
    <Page
      title={
        mode === DetailsPageMode.Create
          ? i18n.translate('ProductRecommendationsDetailsSkeleton.createTitle')
          : i18n.translate('ProductRecommendationsDetailsSkeleton.updateTitle')
      }
      backAction={backAction}
    >
      <Layout>
        <Layout.Section>
          <EnhancedCard
            title={
              mode === DetailsPageMode.Create
                ? i18n.translate(
                    'ProductRecommendationsDetailsSkeleton.sourceProductCard.title',
                  )
                : i18n.translate(
                    'ProductRecommendationsDetailsSkeleton.sourceProductCard.updateTitle',
                  )
            }
          >
            <SkeletonBodyText lines={3} />
          </EnhancedCard>
        </Layout.Section>
        <Layout.Section>
          <EnhancedCard
            padding="0"
            headerPadding="500"
            background={
              mode === DetailsPageMode.Create
                ? 'bg-surface-secondary'
                : 'bg-surface'
            }
            title={
              mode === DetailsPageMode.Create
                ? i18n.translate(
                    'ProductRecommendationsDetailsSkeleton.complementaryProductsCard.title',
                  )
                : i18n.translate(
                    'ProductRecommendationsDetailsSkeleton.complementaryProductsCard.updateTitle',
                  )
            }
          >
            <Box padding="500" paddingBlockStart="0">
              <SkeletonBodyText lines={3} />
            </Box>
            <Divider />
            <Box padding="500" background="bg-surface-secondary">
              <SkeletonBodyText lines={1} />
            </Box>
          </EnhancedCard>
        </Layout.Section>
        <Layout.Section>
          <EnhancedCard
            padding="0"
            headerPadding="500"
            background={
              mode === DetailsPageMode.Create
                ? 'bg-surface-secondary'
                : 'bg-surface'
            }
            title={
              mode === DetailsPageMode.Create
                ? i18n.translate(
                    'ProductRecommendationsDetailsSkeleton.relatedProductsCard.title',
                  )
                : i18n.translate(
                    'ProductRecommendationsDetailsSkeleton.relatedProductsCard.updateTitle',
                  )
            }
          >
            <Box padding="500" paddingBlockStart="0">
              <SkeletonBodyText lines={3} />
            </Box>
            <Divider />
            <Box padding="500">
              <SkeletonBodyText lines={1} />
            </Box>
          </EnhancedCard>
        </Layout.Section>
        <Layout.Section>
          <SkeletonPageActions />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
